import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from 'swiper';
import "./ServiceBanner.css"
import {useEffect, useState} from "react";
import $ from "jquery";
import axios from "axios";
import {Image} from "semantic-ui-react";

export const ServiceBanner = () => {

  const [swiperInstance, setSwiperInstance] = useState(null); // State to track initialized swiper;
  const [bannerImageList, setBannerImageList] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/tb-mb-banner`).then((res) => {
      const {bannerList} = res.data;
      const list = bannerList.map(({url}) => `${process.env.REACT_APP_API_BASE_URL}${url}`);
      setBannerImageList(list);
    })
  }, []);


  return (
    <div className="serviceBanner-container">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        direction="horizontal"
        slidesPerView={1}
        className='service-swiper'
        onInit={(swiper) => {
          setSwiperInstance(swiper);
          swiper.slideToLoop(0,0)
        }}
        loopAdditionalSlides={bannerImageList.length}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {bannerImageList.map((img, idx) =>
          <SwiperSlide className="service-swiper-slide" key={`${idx}_${img}`}>
            <Image className='banner-img' src={img} alt="Service Banner"/>
          </SwiperSlide>
        )}

        <div className="navigation-button-wrapper">
          <div className="navigation-button" onClick={() => swiperInstance?.slidePrev()}>
            <img alt={'arrow-left'} src={'/images/icon/icon_arrow_left_40.svg'}/>
          </div>
          <div className="navigation-button" onClick={() => swiperInstance?.slideNext()}>
            <img className="arrow-image-right" alt={'arrow-left'} src={'/images/icon/icon_arrow_left_40.svg'}/>
          </div>
        </div>
      </Swiper>
    </div>
  )

}


export const ServiceBannerMo = () => {

  const [swiperInstance, setSwiperInstance] = useState(null); // State to track initialized swiper
  const [bannerImageList, setBannerImageList] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/tb-mb-banner`).then((res) => {
      const {moBannerList} = res.data;
      const list = moBannerList.map(({url}) => `${process.env.REACT_APP_API_BASE_URL}${url}`);
      setBannerImageList(list);
    })
  }, []);

  return (
    <div className="serviceBanner-container-mo">
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        direction="horizontal"
        slidesPerView={1}
        className='service-swiper-mo'
        onInit={(swiper) => {
          setSwiperInstance(swiper);
          swiper.slideToLoop(0,0)
        }}
        loopAdditionalSlides={bannerImageList.length}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={500}
      >
        {bannerImageList.map((img) =>
          <SwiperSlide className="service-swiper-slide-mo">
            <img className="banner-img-mo" alt="banner_img_1" src={img}/>
          </SwiperSlide>
        )}

        <div className='navigation-button-mo left' onClick={() => swiperInstance?.slidePrev()}>
          <img alt={'arrow-left'} src={'/images/icon/icon_arrow_left_40.svg'}/>
        </div>
        <div className='navigation-button-mo right' onClick={() => swiperInstance?.slideNext()}>
          <img className="arrow-image-right" alt={'arrow-left'} src={'/images/icon/icon_arrow_left_40.svg'}/>
        </div>
      </Swiper>
    </div>
  )
}