import {Grid, Image} from "semantic-ui-react";
import React from "react";
import "./ServiceCustomer.css"
import ServiceCustomerLogo from "../assets/serviceCustomerLogo.svg";


export const ServiceCustomer = () => (
    <div className="service-customer-container">
        <div className="service-customer-title">
            <Image src={ServiceCustomerLogo}/>
        </div>
        <img src='/images/img_service_customer_list.png' alt="img_service_customer_list" className="service-customer-list-image" />
    </div>

);

export const ServiceCustomerMo = () => {
  return (
      <div className="service-customer-container-mo" >
        <img  alt="img_service_customer_title_mo" src="/images/img_service_customer_title_mo.svg" width={312}  />
        <img alt="img_service_customer_list" src="/images/img_service_customer_list_mo.png" width={312} />
      </div>
    )
}


