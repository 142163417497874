import './MealBoxIntro.css';
import {useState} from "react";
import {SampleMenuModal, SampleMenuModalMo} from "./SampleMenuModal";
import $ from "jquery";

export const MealBoxIntro = () => {

  const [menuType, setMenuType] = useState();
  const [openModal, setOpenModal] = useState(false);

  const onClickImage = (type) => {
    setOpenModal(true);
    setMenuType(type);
  }

  const goToOnlineRequest = (e) => {
    try {
      console.log("====페이지 접속 통계 스크립트====");
      window.amplitude.track('[클릭] 온라인상담');
    } catch (e) {
      console.log("error : AA JS Unload.");
    }

    let elem = document.getElementById("request");
    //if (elem) elem.scrollIntoView();
    if (elem) {
      $('body,html').animate({scrollTop:elem.offsetTop});
    }
  }

  return (
    <>
      <div id="mealbox_sample_intro" className="mealbox-intro-container" >
        <div className="mealbox-intro-layout" >
          <img className="mealbox-intro-title-img" alt="mealbox_intro_title_img" src={'/images/img_mealbox_intro.svg'} />
          <div className="intro-body-layout" >
            <div className="top-text" >※ 1인 기준</div>
            <img src={'/images/img_menu1.png'} alt={'img_menu1'} className='intro-body-img' onClick={() => onClickImage(3500)} />
            <img src={'/images/img_menu2.png'} alt={'img_menu2'} className='intro-body-img' onClick={() => onClickImage(4500)} />
            <img src={'/images/img_menu3.png'} alt={'img_menu3'} className='intro-body-img' onClick={() => onClickImage(5500)} />
            <img src={'/images/img_menu4.png'} alt={'img_menu4'} className='intro-body-img no-cursor' />
            <img
              src={'/images/img_mealbox_intro_banner.png'}
              alt={'img_mealbox_intro_banner'}
              className='intro-body-img'
              onClick={goToOnlineRequest}
            />
          </div>
        </div>
      </div>
      {openModal && <SampleMenuModal type={menuType} setOpenModal={setOpenModal} />}
    </>
  )
}

export const MealBoxIntroMo = () => {

  const [menuType, setMenuType] = useState();
  const [openModal, setOpenModal] = useState(false);

  const onClickImage = (type) => {
    setOpenModal(true);
    setMenuType(type);
  }

  const goToOnlineRequest = (e) => {
    try {
      console.log("====페이지 접속 통계 스크립트====");
      window.amplitude.track('[클릭] 온라인상담');
    } catch (e) {
      console.log("error : AA JS Unload.");
    }

    let elem = document.getElementById("request");
    //if (elem) elem.scrollIntoView();
    if (elem) {
      $('body,html').animate({scrollTop:elem.offsetTop});
    }
  }

  return (
    <>
      <div id="mealbox_sample_intro" className="mealbox-intro-container-mo" >
        <div className="mealbox-intro-layout-mo" >
          <img className="mealbox-intro-title-img-mo" alt="mealbox_intro_title_img" src={'/images/img_mealbox_intro_mo.svg'} />
          <div className="intro-body-layout-mo">
            <div className="top-text">※ 1인 기준</div>
            <img src={'/images/img_menu1_mo.png'} alt={'img_menu1'} className='intro-body-img' onClick={() => onClickImage(3500)}/>
            <img src={'/images/img_menu2_mo.png'} alt={'img_menu2'} className='intro-body-img' onClick={() => onClickImage(4500)}/>
            <img src={'/images/img_menu3_mo.png'} alt={'img_menu3'} className='intro-body-img' onClick={() => onClickImage(5500)}/>
            <img src={'/images/img_menu4_mo.png'} alt={'img_menu4'} className='intro-body-img no-cursor'/>
            <img
              src={'/images/img_mealbox_intro_banner_mo.png'}
              alt={'img_mealbox_intro_banner'}
              className='intro-body-img'
              onClick={goToOnlineRequest}
            />
          </div>
        </div>
      </div>
      {openModal && <SampleMenuModalMo type={menuType} setOpenModal={setOpenModal} />}
    </>
  )
}