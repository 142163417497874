import React, {useEffect, useRef} from "react";
import 'semantic-ui-css/semantic.min.css';
import {useMediaQuery} from "react-responsive"

import {Faq, FaqMo} from "./view/Faq";
import {RequestForm, RequestFormMo} from "./view/OnlineRequest";
import "./App.css";
import {ServiceGNB, ServiceGNBMo} from "./view/ServiceGnb";
import {ServiceBenefit, ServiceBenefitMo} from "./view/ServiceBenefit";

import {ServiceCustomer, ServiceCustomerMo} from "./view/ServiceCustomer";
import {Footer, FooterMo} from "./view/Footer";
import {GoToTop, GoToTopMo} from "./view/GoToTop";
import {ServiceBanner, ServiceBannerMo} from "./view/ServiceBanner";
import {MealBoxIntro, MealBoxIntroMo} from "./view/MealBoxIntro";
import {MiddleBanner, MiddleBannerMo} from "./view/MiddleBanner";
import {ReviewBanner, ReviewBannerMo} from "./view/ReviewBanner";
import {SubscriptionIntro, SubscriptionIntroMo} from "./view/SubscriptionIntro";
import {BenefitBanner, BenefitBannerMo} from "./view/BenefitBanner";

const App = () => {
  const isPc = useMediaQuery({
    query: "(min-width:1000px)"
  });
  const isMobile = useMediaQuery({
    query: "(max-width:999px)"
  });

  const appRef = useRef(null);

  // 처음 로딩시 페이지뷰 통계 스크립트 실행
  useEffect(() => {
    try {
      console.log("====페이지 접속 통계 스크립트====");
      window.amplitude.track('[뷰] 밀박스25페이지');
    } catch (e) {
      console.log("error : AA JS Unload.");
    }
  }, []);

  useEffect(() => {
    document.addEventListener('wheel', function(event) {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    }, { passive: false });
  }, []);


  const contentChildren = (menuVisible) => {
    return (<>
      {isPc && <BenefitBanner appRef={appRef} />}
      {isMobile && <BenefitBannerMo menuVisible={menuVisible} />}

      {/* 서비스 배너 */}
      {isPc && <ServiceBanner/>}
      {isMobile && <ServiceBannerMo/>}

      {/* 서비스 혜택 안내 */}
      {isPc && <ServiceBenefit/>}
      {isMobile && <ServiceBenefitMo/>}

      {/*mealbox 식단 소개*/}
      {isPc && <MealBoxIntro/>}
      {isMobile && <MealBoxIntroMo/>}

      {/*MiddleBanner*/}
      {isPc && <MiddleBanner/>}
      {isMobile && <MiddleBannerMo/>}

      {/*실제 고객 리뷰*/}
      {isPc && <ReviewBanner/>}
      {isMobile && <ReviewBannerMo/>}

      {/*구독 안내*/}
      {isPc && <SubscriptionIntro/>}
      {isMobile && <SubscriptionIntroMo/>}

      {/* 온라인 상담 */}
      {isPc && <RequestForm/>}
      {isMobile && <RequestFormMo/>}

      {/*/!* FAQ *!/*/}
      {isPc && <Faq/>}
      {isMobile && <FaqMo/>}

      {/*/!* 고객사 리스트 *!/*/}
      {isPc && <ServiceCustomer/>}
      {isMobile && <ServiceCustomerMo/>}

      {/* Footer */}
      {isPc && <Footer/>}
      {isMobile && <FooterMo/>}
    </>);
  }

  return (<div ref={appRef} style={{
    display: "flex",
    flexDirection: "column",
    width:'100%',
    alignItems:'center',
    overflowX:'hidden'
  }}>
      {/* GNB */}
      {isPc && <ServiceGNB appRef={appRef} childrenCB={contentChildren}/>}
      {isMobile && <ServiceGNBMo appRef={appRef} childrenCB={contentChildren}/>}
    </div>);
}

export default App;
