import $ from "jquery";
import {useState} from "react";
import './BenefitBanner.css';
import {GoToTop, GoToTopMo} from "./GoToTop";


export const BenefitBanner = ({appRef}) => {

  const goToOnlineRequest = (e) => {
    try {
      console.log("====페이지 접속 통계 스크립트====");
      window.amplitude.track('[클릭] 온라인상담');
    } catch (e) {
      console.log("error : AA JS Unload.");
    }
    let elem = document.getElementById("request");
    //if (elem) elem.scrollIntoView();
    if (elem) {
      $('body,html').animate({scrollTop:elem.offsetTop - 80});
    }
  }

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/resource/20240328_MEALBOX25.pdf"; // Path to the file in the public folder
    link.download = "밀박스25 소개서"; // Suggested filename
    link.click(); // Programmatically click the link
  };

  return (
    <>
      <div className="benefit-banner-wrapper">
        <div className="benefit-banner">
          <div className="title">
            <img className='logo-image' alt={'logo_image'} src={'/images/MEALBOX25_LOGO.svg'}/>
            <span>상담만 해도 아메리카노를 선물로 드려요</span>
          </div>
          <div className="button-wrapper">
            <div className="intro-download-button" onClick={handleDownload} >
              밀박스25 소개서
              <img className="download-icon-image" alt="download_icon" src={'/images/icon/icon_download.svg'}/>
            </div>
            <div className="orange-button" onClick={goToOnlineRequest} >상담신청</div>
          </div>
          <div className="banner-background"/>
          <img className="cafe25-image" src="/images/img_cafe25_coffe.png" alt="image_cafe25_coffee"/>
        </div>
      </div>
      <GoToTop appRef={appRef} />
    </>
  )
}


export const BenefitBannerMo = ({menuVisible}) => {

  const [isBenefitBanner, setIsBenefitBanner] = useState(true);

  const goToOnlineRequest = (e) => {
    try {
      console.log("====페이지 접속 통계 스크립트====");
      window.amplitude.track('[클릭] 온라인상담');
    } catch (e) {
      console.log("error : AA JS Unload.");
    }

    let elem = document.getElementById("request");
    //if (elem) elem.scrollIntoView();
    if (elem) {
      $('body,html').animate({scrollTop:elem.offsetTop});
    }
  }

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/resource/20240328_MEALBOX25.pdf"; // Path to the file in the public folder
    link.download = "밀박스25 소개서"; // Suggested filename
    link.click(); // Programmatically click the link
  };

  return (
    <>
      <div className="benefit-banner-wrapper-mo">
        <GoToTopMo menuVisible={menuVisible} />
        {isBenefitBanner &&
          <div className="benefit-banner-mo">
            <div className="title">
              <div className="first-line">
                <img className='logo-image-mo' alt={'logo_image'} src={'/images/MEALBOX25_LOGO.svg'}/>
              </div>
              <div className="second-line">
                상담만 해도 아메리카노를 드려요
              </div>
            </div>
            <div className="button-wrapper-mo">
              <div className="intro-download-button-mo" onClick={handleDownload} >
                밀박스25 소개서
                <img className="download-icon-image-mo" alt="download_icon" src={'/images/icon/icon_download.svg'}/>
              </div>
              <div className="orange-button-mo" onClick={goToOnlineRequest} >상담신청</div>
            </div>
            <div className="banner-background-mo"/>
            <div className="delete-button"  onClick={() => setIsBenefitBanner(false)} >
              <img src={'/images/icon/icon_close.svg'} alt={'delete_benefit_banner_icon'} width={16} height={16}/>
            </div>
          </div>
        }
      </div>
    </>
  )
}