import {Image} from "semantic-ui-react";
import React from "react";
import "./ServiceBenefit.css";
import MealBox25Logo from '../assets/logo_benefit.svg';
import BenefitImg from '../assets/benefit.png';

export const ServiceBenefit = () => (
    <div className="benefit">
      <img className="benefit-img" alt='benefit_img' src='/images/img_service_benefit.png' />
      <img className="moo-moo-heart-img" alt="moo_moo_heart_img" src="/images/img_moo_moo_heart.png" />
    </div>
);

export const ServiceBenefitMo = () => (
  <div className="benefit-mo">
    <img className="benefit-img-mo" alt='benefit_img_mo' src='/images/img_service_benefit_mo.png'/>
  </div>
);
