import React, {useRef, useState} from "react";
import {
    Button,
    Sticky,
    Segment,
    Menu,
    Container,
    Transition,
    Image,
    Dimmer
} from "semantic-ui-react";
import "./ServiceGnb.css";
import $ from "jquery";

export const ServiceGNB = ({appRef, childrenCB}) => {
    const gnbRef = useRef();
    const [customerCenterModal, setCustomerCenterModal] = useState(false);

    const goToOnlineRequest = (e) => {
        // document.getElementById("request").scrollIntoView({
        //     behavior: "smooth", // Smooth scrolling animation
        //     block: "start",    // Align to the center of the viewport
        // });
        let elem = document.getElementById("request");
        //if (elem) elem.scrollIntoView();
        if (elem) {
            $('body,html').animate({scrollTop:elem.offsetTop - 80});
        }
    }

    const goToMealBoxSample = () => {

        let elem = document.getElementById("mealbox_sample_intro");
        //if (elem) elem.scrollIntoView();
        if (elem) {
            $('body,html').animate({scrollTop:elem.offsetTop - 80});
        }
    }

    return (
        <>
            <Sticky id="sticky" context={appRef} offset={0.2}>
                <div className="gnb" ref={gnbRef}>
                    <div className="gnb-frame">
                        <div className="gnb-frame-left">
                            <div className="gnb-logo" onClick={(e) => {
                                window.location = "/";
                            }}>
                                <img src='./images/MEALBOX25_LOGO.svg' style={{cursor: "pointer"}} alt=""/>
                            </div>
                        </div>
                        <div className="top-menu">
                            <div className="top-menu-left" >
                                <div className="menu-button"  onClick={goToMealBoxSample} >샘플보기</div>
                                <div className="orange-menu-button" onClick={() => setCustomerCenterModal(true)}>
                                    고객센터
                                </div>
                            </div>
                            <Button className="cnsl-btn" color='orange' style={{
                                background: "#FF5A00",
                                margin: 0,
                            }}
                                    data-sc-action='button.click'
                                    data-sc-value='상담문의'
                                    onClick={goToOnlineRequest}>상담신청</Button>
                        </div>
                    </div>
                </div>
            </Sticky>
            {childrenCB(false)}
            {customerCenterModal &&
                <div className="customer-modal-container">
                    <div className="customer-modal-dimmer" onClick={() => setCustomerCenterModal(false)} ></div>
                    <div className="customer-modal-layout">
                        <div className="customer-modal-header">고객센터</div>
                        <div className="customer-modal-body">
                            <svg width="13" height="16" viewBox="0 0 13 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.96512 10.2675L7.1693 10.5262C5.41179 9.40273 4.37633 8.21145 3.87976 6.46724L4.67191 3.74843L3.96484 1.05467L1.46755 0.802703C0.716851 0.726961 0.062633 1.29237 0.0992911 2.05157C0.190809 3.94691 0.674537 7.41544 2.84172 10.0895C5.11761 12.8977 8.65466 14.3735 10.6184 15.0164C11.3768 15.2647 12.1262 14.7246 12.2068 13.9249L12.4494 11.5205L9.96512 10.2675Z"
                                    fill="black"/>
                            </svg>
                            <div className="customer-modal-body-text" >02 - 2006 - 3037</div>
                        </div>
                        <div
                            className="customer-modal-bottom-button"
                            onClick={() => setCustomerCenterModal(false)}
                        >확인</div>
                    </div>
                </div>
            }
        </>
    );
}

export const ServiceGNBMo = ({appRef, childrenCB}) => {

    const [visible, setVisible] = React.useState(false);
    const [dimmed, setDimmed] = useState(false);

    const outsideRef = React.useRef(null);
    const gnbRef = useRef();

    // 상단 메뉴 외 영역 클릭 시
    const useOutsideClick = (ref) => {
        React.useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (!visible) {
                        setVisible(false);
                    }
                }
            }

            // 현재 document에 이벤트리스너를 추가합니다.
            document.addEventListener("mousedown", handleClickOutside);

            // useEffect 함수가 return하는 것은 마운트 해제하는 것과 동일합니다.
            // 즉, Class 컴포넌트의 componentWillUnmount 생명주기와 동일합니다.
            // 더 이상'mousedown'이벤트가 동작하더라도 handleClickOutside 함수가 실행되지 않습니다.
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]); // ref가 변경되면 useEffect를 다시 생성합니다.
    };

    useOutsideClick(outsideRef);

    const goToOnlineRequest = (e) => {
        try {
            console.log("====페이지 접속 통계 스크립트====");
            window.amplitude.track('[클릭] 온라인상담');
        } catch (e) {
            console.log("error : AA JS Unload.");
        }

        setVisible(false);
        setDimmed(false);
        let elem = document.getElementById("request");
        //if (elem) elem.scrollIntoView();
        if (elem) {
            $('body,html').animate({scrollTop:elem.offsetTop});
        }
    }

    const goToMealBoxSample = () => {
        setVisible(false);
        setDimmed(false);

        let elem = document.getElementById("mealbox_sample_intro");
        //if (elem) elem.scrollIntoView();
        if (elem) {
            $('body,html').animate({scrollTop:elem.offsetTop});
        }
    }

    const menuItemStyle = {
        display: "flex",
        alignItems: "center",
        padding: "18px 4px",
        margin: "0px",
        height: "56px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "20px",
        /* identical to box height, or 125% */
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.88)",
    }

    return (
        <>
            <div ref={outsideRef} style={{alignSelf:'flex-start'}}>
                <Sticky id="sticky" context={appRef} offset={0.2}>
                    <div className="gnb-mo-wrapper" style={{backgroundColor:'#FFFFFF'}}  ref={gnbRef}>
                        <Image src="./images/MEALBOX25_LOGO_MO.svg" className="mealbox-icon-mo" style={{cursor: "pointer"}}></Image>
                        <div className="gnb-mo-right-wrapper" >
                            <Button
                                as='a'
                                color='orange'
                                className='cnsl-btn-mo'
                                style={{background: "#FF5A00"}}
                                data-sc-action='button.click'
                                data-sc-value='상담문의'
                                onClick={goToOnlineRequest}
                            >
                                상담신청
                            </Button>
                            <div onClick={() => {
                                setVisible(!visible);
                                setDimmed(!dimmed);
                            }} >
                                <Image src="./images/icon/icon_menu.svg"/>
                            </div>
                        </div>
                        {/*<Segment*/}
                        {/*    textAlign='center'*/}
                        {/*    style={{*/}
                        {/*        height: "52px",*/}
                        {/*        padding: '0.5em 0em',*/}
                        {/*        border: "0px",*/}
                        {/*    }}*/}
                        {/*    vertical*/}
                        {/*>*/}
                            {/*<Menu*/}
                            {/*    text*/}
                            {/*    fixed='top'*/}

                            {/*    size='large'*/}
                            {/*    color='black'*/}
                            {/*    style={{*/}
                            {/*        height: "52px",*/}
                            {/*        padding: '16px 3px 16px 11px',*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Container id="container" >*/}
                            {/*        <Menu.Item position='left' onClick={(e) => {*/}
                            {/*            window.location = "/";*/}
                            {/*        }}>*/}
                            {/*            <Image src="./images/MEALBOX25_LOGO_MO.svg" className="mealbox-icon-mo" style={{cursor: "pointer"}}></Image>*/}
                            {/*        </Menu.Item>*/}
                            {/*        <Menu.Item position='right' >*/}
                            {/*            <Button*/}
                            {/*                as='a'*/}
                            {/*                color='orange'*/}
                            {/*                className='cnsl-btn-mo'*/}
                            {/*                style={{background: "#FF5A00"}}*/}
                            {/*                data-sc-action='button.click'*/}
                            {/*                data-sc-value='상담문의'*/}
                            {/*                onClick={goToOnlineRequest}*/}
                            {/*            >*/}
                            {/*                상담신청*/}
                            {/*            </Button>*/}
                            {/*        </Menu.Item>*/}
                            {/*        <Menu.Item onClick={(e, data) => {*/}
                            {/*            setVisible(!visible);*/}
                            {/*            setDimmed(!dimmed);*/}
                            {/*        }}>*/}
                            {/*            <Image src="./images/icon/icon_menu.svg"/>*/}
                            {/*        </Menu.Item>*/}
                            {/*    </Container>*/}
                            {/*</Menu>*/}
                        {/*</Segment>*/}
                    </div>
                    <Transition
                        visible={visible}
                        animation='fade down'
                        duration={100}
                    >
                        <Segment attached style={{padding: '0', margin: '0', borderTop:'none'}}>
                            <Container>
                                <Menu
                                    className='menusidebar'
                                    text
                                    fluid
                                    vertical
                                    size='large'
                                    color='black'
                                    style={{
                                        margin: "0px",
                                        background: 'white',
                                        textAlign: 'left',
                                        marginLeft: '0.3rem'
                                    }}
                                >
                                    <Menu.Item style={menuItemStyle} onClick={goToMealBoxSample}>
                                        샘플보기
                                    </Menu.Item>
                                </Menu>
                            </Container>
                            <div
                                className='customer-menu'
                            >
                                <div
                                    onClick={() => window.open('tel:0220063037', '_self')}
                                    className="customer-menu-item"
                                >
                                    <div className="customer-menu-item-button" >
                                        고객센터
                                    </div>
                                </div>
                            </div>
                        </Segment>
                    </Transition>

                </Sticky>
            </div>
            <Dimmer.Dimmable as={Segment} dimmed={dimmed} style={{margin: "0px", padding: "0px", border: "none"}}>
                {childrenCB(visible)}
                <Dimmer page active={dimmed} simple onClickOutside={() => setDimmed(false)}/>
            </Dimmer.Dimmable>
        </>
    );
}
