
import './MiddleBanner.css';

export const MiddleBanner = () => {

  return (
    <>
      <img className="middle-banner-img" src="/images/img_middle_banner.png" alt="middle_banner" />
    </>
  )
}

export const MiddleBannerMo = () => {
  return (
    <>
      <img className="middle-banner-img" src="/images/img_middle_banner_mo.png" alt="middle_banner"/>
    </>
  )
}