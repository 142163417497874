import React from "react";
import {Sticky} from "semantic-ui-react";
import {getWindowInnerHeight, getWindowInnerWidth} from "../utils/windowUtil";
import {getWindowScrollY} from "../utils/windowUtil";

export const GoToTop = ({appRef}) => {
    const [showBtn, setShowBtn] = React.useState(false);

    React.useEffect(() => {
        const handleScroll = () => {
            let scrollY = getWindowScrollY();
            let newShowBtn = (scrollY < 100 ? false : true);
            if (showBtn !== newShowBtn) {
                setShowBtn(newShowBtn);
            }
        }

      document.addEventListener("scroll", handleScroll);

      return () => {
        document.removeEventListener("scroll", handleScroll);
      };
    }, [showBtn]);

    const gotoStyle = {
        position: "absolute",
        left: (getWindowInnerWidth() * 0.89) + "px" ,
        top: (getWindowInnerHeight() - 104) + "px",
        cursor: "pointer",
    }
    if (!showBtn) {
        gotoStyle.display = "none";
    }

    function goToTop() {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
      <Sticky id="sticky" context={appRef} offset={0.8}>
          <div style={{
              width:'60px',
              height:'60px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              backgroundColor:'#FFFFFF',
              borderRadius:'50%',
              boxShadow:'0px 3px 3px 0px #00000040',
              ...gotoStyle
          }}
               onClick={goToTop}
          >
            <img src='./images/icon/icon_goto_top.svg' style={{width:'30px', height:'30px'}} alt=""/>
          </div>
      </Sticky>
    );
}

export const GoToTopMo = ({appRef, menuVisible}) => {
    const [showBtn, setShowBtn] = React.useState(false);

    React.useEffect(() => {
        const handleScroll = () => {
            let scrollY = getWindowScrollY();
            let newShowBtn = (scrollY < 60 ? false : true);
            if (showBtn !== newShowBtn) {
                setShowBtn(newShowBtn);
            }
        }

        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [showBtn]);

    const gotoStyle = {
        width: "24px",
        height: "24px",
    }

    function goToTop() {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        (menuVisible ? <></> :
          showBtn &&
          <div style={{
              width:'48px',
              height:'48px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              backgroundColor:'#FFFFFF',
              boxShadow:'0px 3px 3px 0px #00000040',
              alignSelf:'flex-end',
              borderRadius:'50%',
              marginBottom:'40px',
              marginRight:'20px'
            }}
               onClick={goToTop}
          >
            <img src='./images/icon/icon_goto_top.svg' style={gotoStyle} alt=""/>
          </div>
        )
    );
}
