
import './ReviewBanner.css';
import {useMemo, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";

export const ReviewBanner = () => {

  const [swiperInstance, setSwiperInstance] = useState(null); // State to track initialized swiper
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호
  const [totalPages, setTotalPages] = useState(0);  // 전체 페이지 번호

  const handleSwiperInit = (swiper) => {
    setTotalPages(swiper.slides.length); // loop 제외한 슬라이드 개수
    setCurrentPage(swiper.realIndex + 1); // 현재 페이지 설정
  };

  const handleSlideChange = (swiper) => {
    setCurrentPage(swiper.realIndex + 1); // 현재 페이지 업데이트
  };

  const formatPageNumber = (number) => {
    return String(number).padStart(2, '0'); // 두 자리 숫자로 포맷팅
  };



  return (
    <div className="review-banner-container" >
      <div className="review-banner-layout" >
        <div className="review-banner-top" >
          <div className="review-banner-top-left" >
            <img src="/images/MEALBOX25_LOGO.svg" className="logo-image" alt="logo_image" />
            <div className="review-banner-title" >실제 고객 리뷰</div>
          </div>
          <div className="review-banner-top-right" >
            <div className="swiper-button" onClick={() => swiperInstance?.slidePrev()} >
              <img src="/images/icon/icon_arrow_left_40.svg" width={24} height={24} />
            </div>
            <div className="swiper-page-number-layout" >
              <span className="swiper-page-number current" >{formatPageNumber(currentPage)}</span>
              <span>/</span>
              <span className="swiper-page-number whole" >{formatPageNumber(totalPages)}</span>
            </div>
            <div className="swiper-button right" onClick={() => swiperInstance?.slideNext()} >
              <img src="/images/icon/icon_arrow_left_40.svg" width={24} height={24}/>
            </div>
          </div>
        </div>
        <div className="reivew-banner-body" >
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            direction="horizontal"
            slidesPerView={1}
            className="review-swiper"
            onInit={setSwiperInstance}
            onSwiper={handleSwiperInit}
            onSlideChange={handleSlideChange}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            speed={1000}
          >
            <SwiperSlide className="review-swiper-slide">
              <img className="review-banner-img" src="/images/img_review_banner1.png" alt="reivew_banner_img" />
            </SwiperSlide>
            <SwiperSlide className="review-swiper-slide">
              <img className="review-banner-img" src="/images/img_review_banner2.png" alt="reivew_banner_img" />
            </SwiperSlide>
            <SwiperSlide className="review-swiper-slide">
              <img className="review-banner-img" src="/images/img_review_banner3.png" alt="reivew_banner_img" />
            </SwiperSlide>
            <SwiperSlide className="review-swiper-slide">
              <img className="review-banner-img" src="/images/img_review_banner4.png" alt="reivew_banner_img" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export const ReviewBannerMo = () => {

  const [swiperInstance, setSwiperInstance] = useState(null); // State to track initialized swiper
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호
  const [totalPages, setTotalPages] = useState(0);  // 전체 페이지 번호

  const handleSwiperInit = (swiper) => {
    setTotalPages(swiper.slides.length); // loop 제외한 슬라이드 개수
    setCurrentPage(swiper.realIndex + 1); // 현재 페이지 설정
  };

  const handleSlideChange = (swiper) => {
    setCurrentPage(swiper.realIndex + 1); // 현재 페이지 업데이트
  };

  const formatPageNumber = (number) => {
    return String(number).padStart(2, '0'); // 두 자리 숫자로 포맷팅
  };

  const reviewImages = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => `/images/img_review_banner${i + 1}_mo.png`);
  }, [])

  return (
    <div className="review-banner-container-mo" >
      <div className="review-banner-layout-mo" >
        <div className="review-banner-top-mo" >
          <img className="logo-img-mo" src="/images/MEALBOX25_LOGO.svg" alt="logo_image" />
          <span className="review-banner-title-mo" >실제 고객 리뷰</span>
        </div>
        <div className="review-banner-body-mo" >
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            direction="horizontal"
            slidesPerView={1}
            className="review-swiper-mo"
            onInit={setSwiperInstance}
            onSwiper={handleSwiperInit}
            onSlideChange={handleSlideChange}
            spaceBetween={16}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            speed={500}
          >
            {reviewImages.map((src, i) => (
              <SwiperSlide key={i} className="review-swiper-slide-mo">
                <img className="review-banner-img-mo" src={src} alt={`review_banner_img_${i + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="review-banner-pagination">
            <div className="swiper-button-mo" onClick={() => swiperInstance?.slidePrev()} >
              <img src="/images/icon/icon_arrow_left_40.svg" width={28} height={28}/>
            </div>
            <div className="swiper-page-number-layout-mo">
              <span className="swiper-page-number-mo current">{formatPageNumber(currentPage)}</span>
              <span className="slash-mo" >/</span>
              <span className="swiper-page-number-mo whole">{formatPageNumber(totalPages)}</span>
            </div>
            <div className="swiper-button-mo right"  onClick={() => swiperInstance?.slideNext()} >
              <img src="/images/icon/icon_arrow_left_40.svg" width={28} height={28}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}