import React from "react";
import {Accordion, Icon, Image} from 'semantic-ui-react'
import {Grid} from 'semantic-ui-react'
import axios from "axios";
import "./Faq.css"
import FaqLogo from "../assets/faqLogo.svg";
import Markdown from "markdown-to-jsx";

export const Faq = () => {
  const [activeIndex, setActiveIndex] = React.useState('');
  const [faqList, setFaqList] = React.useState([]);

  const handleClick = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  }

  const faqTitleStyle = {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px", /*56px;*/
    // alignItems: "center",
    color: "#000000"
  }
  const faqContentStyle = {
    fontSize: "16px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "normal",
    padding: ".5em 1em 1.5em 1.5em",
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
  }


  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/tb-mb-home`).then((res) => {
      setFaqList(res.data.FAQ);
      console.log(res.data);
    });
  }, []);


  return (
    <div className="faq">
      <div className="faqImageDiv">
        <Image src="/images/MEALBOX25_LOGO.svg" style={{width:'242px'}} width="100%"/>
        <div className="faq-title" >자주 묻는 질문</div>
        <img className="image-moo-moo" src="/images/img_moomoo1.png" alt="moo_moo_image" />
      </div>
      <Grid style={{width: "928px", marginLeft: "260px", marginRight: "260px", marginBottom: "64px"}}>
        <Grid.Row>
          <Grid.Column>
            <Accordion styled
                       style={{
                         width: "100%",
                         border: "1px solid #E5E5E5",
                         boxShadow: "none"
                       }}
            >
              {
                faqList.map(({Question, Answer}, index) => {
                  // const iconName = activeIndex === index ? 'dropdown' : 'dropdown'
                  let titleStyle = {...faqTitleStyle};
                  if (index === 0) {
                    titleStyle.borderTop = '0px';
                  }
                  return (
                    <div key={`acc-${index}`}>
                      <Accordion.Title
                        active={activeIndex === index}
                        index={index}
                        onClick={handleClick}
                        style={titleStyle}
                      >
                        <Icon name='dropdown' style={{fontSize: "20px"}}
                              className="Faq-accordion-title-icon"/>
                        {Question}
                      </Accordion.Title>
                      <Accordion.Content
                        style={faqContentStyle}
                        active={activeIndex === index}>
                        <Markdown>{Answer}</Markdown>
                      </Accordion.Content>
                    </div>
                  )
                })
              }
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
export const FaqMo = () => {
  const [activeIndex, setActiveIndex] = React.useState('');
  const [faqList, setFaqList] = React.useState([]);

  const handleClick = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  }

  const faqTitleStyle = {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "28px",
    color: "#000000",

    // border: "1px solid #E5E5E5",
    // borderTopLeftRadius: "8px"
  }
  const faqContentStyle = {
    fontSize: "14px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "normal",
    padding: ".5em 1em 1.5em 1.5em",
    overflowWrap: 'break-word',
  }

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/tb-mb-home`).then((res) => {
      setFaqList(res.data.FAQ);
    });
  }, []);

  return (
    <div className="faqMo">
      <div className="faqImageDivMo">
        <Image className="faq-title-mo" src="/images/img_faq_title_mo.svg"/>
      </div>
      <Grid style={{width: "100%", marginLeft: "10px", marginRight: "10px", position:'relative'}}>
        <img className="image-moo-moo-mo" src="/images/img_moomoo1.png" alt="image_moo_moo" />
        <Grid.Row>
          <Grid.Column>
            <Accordion styled
                       style={{
                         width: "100%",
                         border: "1px solid #E5E5E5",
                         boxShadow: "none"
                       }}
            >
              {
                faqList.map(({Question, Answer}, index) => {
                  // const iconName = activeIndex === index ? 'dropdown' : 'dropdown'
                  let titleStyle = {...faqTitleStyle};
                  if (index === 0) {
                    titleStyle.borderTop = '0px';
                  }
                  return (
                    <div key={`acc-${index}`}>
                      <Accordion.Title
                        active={activeIndex === index}
                        index={index}
                        onClick={handleClick}
                        style={titleStyle}
                      >
                        <Icon name='dropdown' style={{fontSize: "20px"}}
                              className="Faq-accordion-title-icon"/>
                        {Question}
                      </Accordion.Title>
                      <Accordion.Content
                        style={faqContentStyle}
                        active={activeIndex === index}>
                        <Markdown>{Answer}</Markdown>
                      </Accordion.Content>
                    </div>
                  )
                })
              }

              {/*<div key={`acc-99`}>*/}
              {/*  <Accordion.Title*/}
              {/*    active={activeIndex === 99}*/}
              {/*    index={99}*/}
              {/*    onClick={handleClick}*/}
              {/*    style={faqTitleStyle}*/}
              {/*  >*/}
              {/*    <Icon name='dropdown' style={{fontSize: "20px"}}*/}
              {/*          className="Faq-accordion-title-icon"/>*/}
              {/*    10인 미만 고객은 어떻게 이용하면 되나요?*/}
              {/*  </Accordion.Title>*/}
              {/*  <Accordion.Content*/}
              {/*    style={faqContentStyle}*/}
              {/*    active={activeIndex === 99}*/}
              {/*  >*/}
              {/*    <div style={{display: 'flex', flexWrap: 'wrap'}}>*/}
              {/*      <p style={{marginRight: '8px'}}>10인 미만 고객분들은 우리동네GS앱의 ‘편의점 사전 예약’에서 밀박스25를 만나볼수 있습니다</p>*/}
              {/*      <div style={{display: 'flex'}}>*/}
              {/*        <div>▶▶</div>*/}
              {/*        <a href={'https://abr.ge/j1usds'}*/}
              {/*           style={{textDecoration: 'underline', cursor: 'pointer'}}>우리동네GS 앱*/}
              {/*          다운로드</a>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </Accordion.Content>*/}
              {/*</div>*/}
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>

    </div>
  )
}

