import './SampleMenuModal.css';
import {useEffect, useMemo} from "react";

export const SampleMenuModal = ({
                                  type,
                                  setOpenModal
                                }) => {

  useEffect(() => {
    // Set body overflow-y to hidden when the component mounts
    document.body.style.overflowY = "hidden";

    // Cleanup function to reset overflow-y when the component unmounts
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  const title = useMemo(() => {
    if (!type) return;

    if (type === 3500) return '3,500원대 샘플 메뉴 구성';
    if (type === 4500) return '4,500원대 샘플 메뉴 구성';
    if (type === 5500) return '5,500원대 샘플 메뉴 구성';


  }, [type])

  const imagePath = useMemo(() => {
    if (!type) return;

    if (type === 3500) return '/images/sample_menu_3500.png';
    if (type === 4500) return '/images/sample_menu_4500.png';
    if (type === 5500) return '/images/sample_menu_5500.png';
  }, [type])

  const descriptionList = useMemo(() => {
    return [
      "원하는 상품으로 각 1개씩 선택하실 수 있습니다.",
      "일자별 매일 구성이 변경됩니다.",
      "월간 식단표로 운영되며, 메인메뉴 종류와 일자선택시 해당일자의 메뉴를 받아볼수 있습니다."
    ]
  }, [])

  return (
    <>
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">{title}</div>
          <img onClick={() => setOpenModal(false)} src="/images/icon/icon_close.svg" alt="icon_close_image"
               className="modal-exit-button"/>
        </div>
        <div className="modal-body" >
          <div className="modal-image-wrapper">
            <img className="modal-image" alt="sample_menu_image" src={imagePath}/>
          </div>
          <div className="modal-bottom">
            <div className="bottom-title">유의사항</div>
            <div className="bottom-description-list">
              {descriptionList.map((description) =>
                <div className="bottom-description">{description}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-dimmed-container" onClick={() => setOpenModal(false)}/>
    </>
  )
}


export const SampleMenuModalMo = ({
                                    type,
                                    setOpenModal
                                  }) => {

  const title = useMemo(() => {
    if (!type) return;

    if (type === 3500) return '3,500원대 샘플 메뉴 구성';
    if (type === 4500) return '4,500원대 샘플 메뉴 구성';
    if (type === 5500) return '5,500원대 샘플 메뉴 구성';


  }, [type])

  const imagePath = useMemo(() => {
    if (!type) return;

    if (type === 3500) return '/images/sample_menu_3500_mo.png';
    if (type === 4500) return '/images/sample_menu_4500_mo.png';
    if (type === 5500) return '/images/sample_menu_5500_mo.png';
  }, [type])

  const descriptionList = useMemo(() => {
    return [
      "원하는 상품으로 각 1개씩 선택하실 수 있습니다.",
      "일자별 매일 구성이 변경됩니다.",
      "월간 식단표로 운영되며, 메인메뉴 종류와 일자선택시 해당일자의 메뉴를 받아볼수 있습니다."
    ]
  }, [])

  useEffect(() => {
    // Set body overflow-y to hidden when the component mounts
    document.body.style.overflowY = "hidden";

    // Cleanup function to reset overflow-y when the component unmounts
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <div className="modal-container-mo" >
      <div className="modal-layout-mo">
        <div className="modal-header">
          <div className="modal-title">{title}</div>
          <img onClick={() => setOpenModal(false)} src="/images/icon/icon_close.svg" alt="icon_close_image"
               className="modal-exit-button"/>
        </div>
        <div className="modal-body-mo" >
          <div className="modal-image-wrapper">
            <img className="modal-image" alt="sample_menu_image" src={imagePath}/>
          </div>
          <div className="modal-bottom">
            <div className="bottom-title">유의사항</div>
            <div className="bottom-description-list">
              {descriptionList.map((description) =>
                <div className="bottom-description">{description}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-dimmed-container" onClick={() => setOpenModal(false)}/>
    </div>
  )
}