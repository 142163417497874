

import './SubscriptionIntro.css';

export const SubscriptionIntro = () => {


  return (
    <div className="subscription-intro-container" >
      <img className="subscription-img" src="/images/img_subscription_intro.png" alt="subscription_img" />
    </div>
  )
}

export const SubscriptionIntroMo = () => {

  return (
    <div className="subscription-intro-container-mo" >
      <img className="subscription-img-mo" src="/images/img_subscription_intro_mo.png" alt="subscription_img"/>
    </div>
  )
}